// Same as gatsby-node.js getCategoryId
function getCategoryIdFromPreviewData(previewData) {
  return previewData.prismicItem.data.category.replace(/(\W|\s)/g, '-').toLowerCase()
}
const getGlobalDataFromPreviewData = (previewData, PAGE_TYPE) => {
  const imageItems = getImageItemsFromPreviewData(previewData)
  const uid = previewData.prismicItem.uid
  const shopLink = `/shop/${getCategoryIdFromPreviewData(previewData)}/${uid}/`
  const item = {
    ...previewData.prismicItem.data,
    uid,
    shopLink,
    imageItems,
  }

  if (PAGE_TYPE === "shop") {
    return {
      PAGE_TYPE,
      items: [item],
    }
  } else {
    return {
      PAGE_TYPE,
      ...item,
    }
  }
}

const getImageItemsFromPreviewData = previewData => {
  const imageSlice = previewData.prismicItem.data.body.find(
    slice => slice.slice_type === "images"
  )

  return imageSlice.items.map(rawImageItem => ({
    listingPageImage: rawImageItem.image.fluid,
    shopThumbnailImage: rawImageItem.image.fixed
      ? {
          ...rawImageItem.image.fixed,
          width: 100,
          height: 100,
        }
      : null,
    shopImage: rawImageItem.image.fluid || null,
    order: rawImageItem.order1,
    include_in_collections:
      rawImageItem.include_in_collections === `Yes` ? true : false,
    include_in_shop: rawImageItem.include_in_shop === `Yes` ? true : false,
  }))
}

export default getGlobalDataFromPreviewData