import React, { useEffect } from "react"
import { usePrismicPreview } from "gatsby-source-prismic"
import { graphql, useStaticQuery, navigate } from "gatsby"

import previewPathResolver from "../utils/previewPathResolver"
import getGlobalDataFromPreviewData from "../utils/getGlobalDataFromPreviewData"
import Seo from "../components/seo"

const PreviewPage = props => {
  // * PAGE_TYPE = <shop || item> - attached to the URL by Prismic as configured within Previews
  let PAGE_TYPE = null
  const match = props.location.search.match(/\?page=([^&]+)/)
  if (match && match.length) {
    PAGE_TYPE = match[1]
  }

  const { allSitePage } = useStaticQuery(graphql`
    {
      allSitePage {
        nodes {
          path
        }
      }
    }
  `)
  const allPaths = allSitePage.nodes.map(node => node.path)
  const pathResolver = () => doc => previewPathResolver(doc, PAGE_TYPE)

  const { isPreview, previewData, path } = usePrismicPreview({
    repositoryName: "BeRomantic",
    pathResolver,
  })

  // ? This useEffect runs when values from usePrismicPreview update
  useEffect(() => {
    // *  null = Not yet determined if previewing.
    // *  true = Preview is available.
    // *  false = Preview is not available.
    if (
      isPreview === false ||
      isPreview === undefined ||
      path === undefined ||
      previewData === undefined
    ) {
      return
    }

    // ? When preview data is available, save the preview data in the window object (globally accessible)
    const globalPrismicData = getGlobalDataFromPreviewData(
      previewData,
      PAGE_TYPE
    )
    window.__PRISMIC_PREVIEW_DATA__ = globalPrismicData

    // ? redirect to previewed document's page
    if (allPaths.includes(path)) {
      navigate(path)
    } else {
      navigate("/unpublishedPreview")
    }
  }, [isPreview, previewData, path, allPaths, PAGE_TYPE])

  // Tell the user if this is not a preview.
  // TODO add a top div capturing full width noting that this is in preview mode
  if (isPreview === false)
    return (
      <div>
        <Seo noindex />
        Not a preview!
      </div>
    )

  return (
    <div>
      <Seo noindex />
      Loading preview...
    </div>
  )
}

export default PreviewPage
