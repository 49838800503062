export default function previewPathResolver(doc, PAGE_TYPE) {
  const { type: DOC_TYPE, slugs, data } = doc

  if (PAGE_TYPE === "item") {
    if (DOC_TYPE === "item") {
      return `/shop/${data.collection.slug}/${slugs[0]}`
    } else {
      console.warn(
        `\n⚠️ previewPathResolver: Item page requested with invalid doc type = ${DOC_TYPE}`
      )
      return "/"
    }
  } else if (PAGE_TYPE === "shop") {
    if (DOC_TYPE === "item") {
      return `/shop/${data.collection.slug}`
    } else if (DOC_TYPE === "shop") {
      return `/shop/${slugs[0]}`
    }
  } else {
    console.warn(
      `\n⚠️ previewPathResolver: Unknown page type found = ${PAGE_TYPE}`
    )
    return "/"
  }
}
